import { useEffect, useState } from "react";
import { playAudioFromText } from "../utils/audio";

const Translator = () => {
  const [inputText, setInputText] = useState("");
  const [translatedText, setTranslatedText] = useState("");

  useEffect(() => {
    // Function to translate the text
    const translateText = async () => {
      if (inputText) {
        try {
          // Replace 'YOUR_API_KEY' with your actual Google Cloud API key
          const response = await fetch(
            "https://bk-svc-ihhif-dijkosqbnm.ap-southeast-1.fcapp.run/authenticTranslation",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                text: inputText,
              }),
            }
          );
          console.log(response);
          response.json().then((data) => setTranslatedText(data.data));
        } catch (error) {
          console.error("Translation error: ", error);
        }
      } else {
        setTranslatedText(""); // Clear the translation when the input is empty
      }
    };
    // You can adjust the delay (e.g., 500ms) to control how often the translation occurs
    const translationDelay = 200;

    // Use a timer to delay the translation request
    const translationTimer = setTimeout(translateText, translationDelay);

    // Cleanup the timer when the input changes
    return () => clearTimeout(translationTimer);
  }, [inputText]);

  const handleSpeak = () => {
    playAudioFromText(translatedText);
    // Implement text-to-speech functionality here
  };

  return (
    <div className="p-4 max-w-xs mx-auto">
      <div className="mb-4">
        <label htmlFor="inputText" className="text-gray-800 text-sm">
          中文
        </label>
        <textarea
          id="inputText"
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          className="w-full h-40 p-2 text-gray-800 border rounded focus:outline-none focus:#88abdfaf"
        />
      </div>

      <div className="mt-4">
        <label className="text-gray-800 text-sm">英文翻译</label>
        <div className="border p-2 rounded text-gray-800 text-sm h-40 overflow-y-auto">
          {translatedText}
        </div>
      </div>

      <button
        onClick={handleSpeak}
        className="bg-black text-white text-sm px-2 py-2 mt-4 rounded focus:outline-none"
      >
        朗读
      </button>
    </div>
  );
};

export default Translator;
