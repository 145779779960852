import { useState, useEffect, useContext } from "react";
import { converse, transcribe } from "../utils/api";

import ChatTitle from "../components/ChatTitle";
import ChatMessages from "../components/ChatMessages";
import Microphone from "../components/Microphone";
import { UserContext } from "../context/user.context";
import { MessagesContext } from "../context/messages.context";
import { playAudiOnceFromURL } from "../utils/audio";
import ConversationStarter from "../components/ConversationStarter";

const Chat = () => {
  const [audioURL, setAudioURL] = useState<string>("");
  const [chatState, setChatState] = useState<string | null>("start");
  const [audioFile, setAudioFile] = useState<File>(
    new File([], "sample.mp3", { type: "audio/mpeg" })
  );
  const { currentUser } = useContext(UserContext);
  const {
    conversationId,
    messages,
    appendHumanMessage,
    appendAgentMessage,
    getAllMessages,
  } = useContext(MessagesContext);
  const [awaitResponse, setAwaitResponse] = useState(false);

  async function loadConversation() {
    getAllMessages(currentUser!);
  }

  async function handleUserSendResponse() {
    const userResponse = await transcribe(audioFile);
    appendHumanMessage(
      userResponse,
      currentUser?.uid,
      audioURL,
      conversationId
    );
    setAwaitResponse(true);
    const agentResponse = await converse({ input: userResponse });
    playAudiOnceFromURL(agentResponse.audioUrl);
    setAwaitResponse(false);
    appendAgentMessage(agentResponse, conversationId!);
  }

  function getUserAudioInput(audioFile: File, audioURL: string) {
    setAudioFile(audioFile);
    setAudioURL(audioURL);
  }

  useEffect(() => {
    if (currentUser) {
      loadConversation();
    }
  }, [currentUser]);

  async function startConversation() {
    setChatState(null);
    setAwaitResponse(true);
    const agentResponse = await converse({
      input:
        "Please introduce yourself as Zorintha, and ask me an interesting question!",
    });
    playAudiOnceFromURL(agentResponse.audioUrl);
    setAwaitResponse(false);
    appendAgentMessage(agentResponse, conversationId!);
  }

  return (
    //main chat logic
    <div className="flex-1 flex flex-col divide-y divide-dotted">
      <ChatTitle />
      {messages.length === 0 && chatState === "start" ? (
        <ConversationStarter onStart={startConversation} />
      ) : (
        <>
          <ChatMessages messages={messages} awaitResponse={awaitResponse} />
          <Microphone
            onStop={getUserAudioInput}
            onSend={handleUserSendResponse}
          />
        </>
      )}
    </div>
  );
};

export default Chat;
