import { useState, useContext } from "react";
import { UserContext } from "../context/user.context";

import { signInWithGooglePopup } from "../utils/firebase";
import { Button } from "@mui/material";

const SignIn = () => {
  const logGoogleUser = async () => {
    await signInWithGooglePopup();
  };
  return (
    <div className="flex flex-col items-center mt-auto p-4">
      <div className="flex items-center space-x-8">
        <button
          onClick={logGoogleUser}
          className="px-3 py-2 bg-black text-white rounded-lg hover:bg-gray-800"
        >
          使用谷歌登录
        </button>
      </div>
    </div>
  );
};

export default SignIn;
