import { createContext, useState } from "react";
import {
  getConversationByUser,
  createConversationBetweenUserAndChatbot,
  addMessageToConversationById,
} from "../utils/firebase";
import { v4 as uuidv4 } from "uuid";
import { ZORINTHA } from "../constants";

export const MessagesContext = createContext({
  conversationId: "",
  messages: [],
  getAllMessages: (user: any) => {},
  appendAgentMessage: (message: any, conversationId: any) => {},
  appendHumanMessage: (
    humanMessage: any,
    sender: any,
    audioURL: any,
    conversationId: any
  ) => {},
  clearMessages: () => {},
});

export const MessagesProvider = ({ children }: any) => {
  const [messages, setMessages] = useState<any>([]);
  const [conversationId, setConversationId] = useState<string>("");

  const getAllMessages = async (user: any) => {
    const existingConversation = await getConversationByUser(user);
    if (existingConversation) {
      setMessages(existingConversation[0].data().messages);
      setConversationId(existingConversation[0].id);
    } else {
      console.log("Creating new conversation");
      const conversationRef = await createConversationBetweenUserAndChatbot(
        user,
        ZORINTHA
      );
      setMessages([]);
      setConversationId(conversationRef.id);
    }
  };

  const appendAgentMessage = (message: any, conversationId: any) => {
    setMessages((prevMessages: any) => [...prevMessages, message]);
    if (conversationId) {
      addMessageToConversationById(message, conversationId);
    }
  };

  const appendHumanMessage = (
    humanMessage: any,
    sender: any,
    audioURL: any,
    conversationId: any
  ) => {
    const id = uuidv4();
    setMessages((prevMessages: any) => [
      ...prevMessages,
      {
        id: id,
        role: "user",
        sender: sender,
        content: humanMessage,
        audioUrl: audioURL,
      },
    ]);
    if (conversationId) {
      addMessageToConversationById(
        {
          id: id,
          role: "user",
          sender: sender,
          content: humanMessage,
          audioUrl: audioURL,
        },
        conversationId
      );
    }
  };

  const clearMessages = () => {
    setMessages([]);
    setConversationId("");
  };

  const value = {
    conversationId,
    messages,
    getAllMessages,
    appendAgentMessage,
    appendHumanMessage,
    clearMessages,
  };

  return (
    <MessagesContext.Provider value={value}>
      {children}
    </MessagesContext.Provider>
  );
};
