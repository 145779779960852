import { useState, useContext } from "react";
import record from "../assets/icons/record.svg";
import stopRecord from "../assets/icons/stopRecord.svg";
import send from "../assets/icons/send.svg";
import cancel from "../assets/icons/cancel.svg";
import { UserContext } from "../context/user.context";
import { MessagesContext } from "../context/messages.context";
import SignInModal from "./SignInModal";

const Microphone = ({
  onStop,
  onSend,
}: {
  onStop: (audioFile: File, audioURL: string) => void;
  onSend: () => Promise<void>;
}) => {
  const [recordingStage, setRecordingStage] = useState("start");
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [audioURL, setAudioURL] = useState<string | null>(null);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(
    null
  );
  const { currentUser } = useContext(UserContext);
  const { messages } = useContext(MessagesContext);

  async function startRecording() {
    if (!currentUser && messages.length >= 6) {
      setShowLoginModal(true);
      return;
    }
    setRecordingStage("speaking");
    let localAudioChunks: Blob[] = [];
    const constraints = { audio: true };
    navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
      const mediaRecorder = new MediaRecorder(stream);
      setMediaRecorder(mediaRecorder);
      setAudioURL(null);
      mediaRecorder.start();

      mediaRecorder.ondataavailable = (e) => {
        localAudioChunks.push(e.data);
      };

      mediaRecorder.onstop = () => {
        const blob = new Blob(localAudioChunks, { type: "audio/mpeg" });
        const file = new File([blob], "sample.mp3", { type: blob.type });
        const audioURL = URL.createObjectURL(blob);
        onStop(file, audioURL);
        setAudioURL(audioURL);
      };
    });
  }

  async function stopRecording() {
    await mediaRecorder?.stop();
    setRecordingStage("over");
  }

  function cancelTranscription() {
    setRecordingStage("start");
  }

  function sendTranscription() {
    setRecordingStage("start");
    onSend();
  }

  return (
    <div>
      {/* {" "} */}
      <div className="w-full flex relative flex items-center justify-center items-center flex-col py-2">
        {recordingStage === "start" && (
          <div className="flex items-center flex-col">
            <p>轻触开始说话</p>
            <img
              className="w-12 h-12 cursor-pointer"
              onClick={startRecording}
              src={record}
              alt="start recording"
            />
          </div>
        )}
        {recordingStage === "speaking" && (
          <div className="flex items-center flex-col">
            <p>轻触结束说话</p>
            <img
              className="w-12 h-12"
              onClick={stopRecording}
              src={stopRecord}
              alt="stop recording"
            />
          </div>
        )}

        {recordingStage === "over" && (
          <div className="space-x-2 flex justify-between items-center">
            <img
              className="w-8 h-8"
              onClick={cancelTranscription}
              src={cancel}
              alt="cancel"
            />
            <audio src={audioURL ?? ""} controls />
            <img
              className="w-8 h-8"
              onClick={sendTranscription}
              src={send}
              alt="send"
            />
          </div>
        )}
      </div>
      <SignInModal
        isOpen={showLoginModal}
        onClose={() => setShowLoginModal(false)}
      />
    </div>
  );
};

export default Microphone;
