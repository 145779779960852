import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  addDoc,
  collection,
  updateDoc,
  arrayUnion,
  query,
  where,
  getDocs,
} from "firebase/firestore";

import { User, ChatBot, Message } from "../types";

const firebaseConfig = {
  apiKey: "AIzaSyBqmahTYN-3BU_yJ9pspgk1G0nq6Zl9GqM",
  authDomain: "speak-2023.firebaseapp.com",
  projectId: "speak-2023",
  storageBucket: "speak-2023.appspot.com",
  messagingSenderId: "1002607091390",
  appId: "1:1002607091390:web:49dfc5ebd1b01ccf0a88ef",
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);

const provider = new GoogleAuthProvider();

provider.setCustomParameters({
  prompt: "select_account",
});

export const auth = getAuth();
export const signInWithGooglePopup = async () => {
  try {
    await signInWithPopup(auth, provider);
  } catch (error: any) {
    if (error.code === "auth/cancelled-popup-request") {
      console.log("User cancelled the sign-in popup.");
    } else if (error.code === "auth/popup-closed-by-user") {
      console.log("User closed the sign-in popup.");
    } else {
      console.error("An error occurred during sign-in:", error);
    }
  }
};

export const db = getFirestore(app);

export const createUserDocumentFromAuth = async (userAuth: any) => {
  const userDocRef = doc(db, "users", userAuth.uid);
  const userSnapshot = await getDoc(userDocRef);

  if (!userSnapshot.exists()) {
    const { displayName, email } = userAuth;
    const createAt = new Date();
    try {
      await setDoc(userDocRef, {
        displayName,
        email,
        createAt,
      });
    } catch (error) {
      console.log("Error creating the user", error);
    }
  }
  return userDocRef;
};

export const signOutUser = async () => await signOut(auth);

export const onAuthStateChangedListener = (callback: any) =>
  onAuthStateChanged(auth, callback);

export const createConversationBetweenUserAndChatbot = async (
  user: User,
  bot: ChatBot
) => {
  const conversationRef = await addDoc(collection(db, "conversations"), {
    createdBy: user.uid,
    participants: [user, bot],
    messages: [],
  });
  return conversationRef;
};

export const getConversationByUser = async (user: User) => {
  const q = query(
    collection(db, "conversations"),
    where("createdBy", "==", user.uid)
  );
  const querySnapshot = await getDocs(q);

  if (querySnapshot.empty) {
    return null;
  }

  return querySnapshot.docs;
};

export const addMessageToConversationById = async (
  message: Message,
  conversationId: string
) => {
  const conversationDocRef = doc(db, "conversations", conversationId);
  await updateDoc(conversationDocRef, {
    messages: arrayUnion(message),
  });

  return conversationDocRef;
};
