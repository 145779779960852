import axios from "axios";
import { BASE_URL } from "../config/config";
import { Message } from "../types";

export const converse = async (input: Object): Promise<Message> => {
  try {
    const response = await axios.post(BASE_URL + "/conversation", input);
    return response.data;
  } catch (error) {
    console.error("Error submitting data:", error);
    throw error;
  }
};

export const translate = async (text: string) => {
  try {
    const data = {
      text: text,
    };
    const response = await axios.post(BASE_URL + "/translation", data);
    return response.data[0].translations[1].text;
  } catch (error) {
    console.error("Error submitting data:", error);
    throw error;
  }
};

export const consult = async (text: string) => {
  try {
    const data = {
      text: text,
    };
    const response = await axios.post(BASE_URL + "/chatTranslation", data);
    return response.data.text;
  } catch (error) {
    console.error("Error submitting data:", error);
    throw error;
  }
};

export const textToSpeech = async (text: string) => {
  try {
    const data = {
      text: text,
    };
    const response = await axios.post(BASE_URL + "/api/textToSpeech", data);
    return response.data.speech_url;
  } catch (error) {
    console.error("Error submitting data:", error);
    throw error;
  }
};

export const transcribe = async (audioFile: File) => {
  try {
    const formData = new FormData();
    formData.append("avatar", audioFile);
    const header = {
      ContentType: "multipart/form-data",
    };
    const url = BASE_URL + "/transcribe";
    const response = await axios({
      url: url,
      method: "post",
      headers: header,
      data: formData,
    });
    return response.data.transcription;
  } catch (error) {
    console.error("Error submitting data:", error);
    throw error;
  }
};
